import { useState } from 'react'
import axios from 'axios';

const useSGAutorepondeur = () => {
  const [result, setResult] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const addToSGAutorepondeur = async (first_name, email, token) => {

    axios('https://zenoption-backend.herokuapp.com/api/newsletter', {method:'post', 
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
      },
      data: {
        prenom: first_name,
        email: email,
        token: token
      }
    })
    .then(res => {
      
      setResult(res)
      setSubmitting(false)
      window.location.href = "https://zen-option.com/bienvenue-au-club-zenoption";
    })
    .catch(e =>{
      setSubmitting(false)
      console.log('An error occured while sending')
    });
  };

  const handleSubmit = async (target, token) => {
   //e.preventDefault()
    setSubmitting(true)
    const data = new FormData(target)
    const first_name = data.get('first_name')
    const email = data.get('email')
    const honeypot = data.get('ohnohoney')
    //spam prevention
    if (honeypot) {
      setSubmitting(false)
      return false
    }
    const rep = await addToSGAutorepondeur(first_name,email, token)
  }

  const success = result && result.status === 200
  const error = result && result.status !== 200
  const canSubmit = !result || error
  const message = success ? 'Ajouté à la liste avec succès' : ''

  return {
    handleSubmit,
    canSubmit,
    submitting,
    message,
    success,
    error
  }
}

export default useSGAutorepondeur
