import React from 'react'
import { Helmet } from "react-helmet"
import PropTypes from 'prop-types'
import { Flex, Box, Input, Button, Message, Spinner } from 'theme-ui'

const styles = {
  msg: {
    m: 2,
  },
  button: {
    fontWeight:`bold`,
    display: `block`,
    width:`full`,
    mx: `auto`,
    borderRadius: `3px`,
    boxShadow : `0px 4px 4px rgba(0, 0, 0, 0.2)`,
    textTransform: `uppercase`
  },
  column:{
    display: [`block`, `block`, `block`],
    size:['1/4', '1/3',],
    p:3,
    textAlign: `center`
  }
}

const NewsletterForm = ({
  handleSubmit,
  canSubmit,
  submitting,
  message,
  success,
  buttonTitle,
  inline
}) => {

  const handleSubmitForm = async (e) => {
    let target = e.target
    e.preventDefault();
    grecaptcha.ready(function() {
      grecaptcha.execute('6LeDTWInAAAAAMsQkQC2f7dBt0mlDmm6Jd6UDsrF', {action: 'submit'}).then(function(token) {
        handleSubmit(target, token)
      });
    }); 
  }

  return (
    <>
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js?render=6LeDTWInAAAAAMsQkQC2f7dBt0mlDmm6Jd6UDsrF"></script>
      </Helmet>
      <form onSubmit={handleSubmitForm}>
        {message && (
          <Message
            variant={success ? 'success' : 'error'}
            sx={styles.msg}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {canSubmit && !inline && (
          <>
            <Box variant='forms.row'>
              <Input
                name='first_name'
                type='text'
                placeholder='Votre prénom'
                aria-label='Prénom'
                required
                sx={{m:1}}
              />
              <Input
                name='email'
                type='email'
                placeholder='Votre email'
                aria-label='VEmail'
                sx={{m:1}}
                required
              />
             <input name="ohnohoney" type="hidden" placeholder="hi there" />
            </Box>
            <Box>
              <Button
                type='submit'
                variant={success || submitting ? 'disabled' : 'primary'}
                disabled={success || submitting}
                sx={styles.button}
              >
                {submitting ? <Spinner size='20' /> : buttonTitle}
              </Button>
            </Box>
          </>
        )}
        {canSubmit && inline && (
          <>
            <Flex>
              <Box sx={styles.column}>
                <Input
                  name='first_name'
                  type='text'
                  placeholder='Votre prénom'
                  aria-label='Prénom'
                  required
                  sx={{m:1}}
                />
              </Box>
              <Box sx={styles.column}>
                <Input
                  name='email'
                  type='email'
                  placeholder='Votre email'
                  aria-label='Email'
                  sx={{m:1}}
                  required
                />
              </Box>
              <Box sx={styles.column}>
                <Button
                  type='submit'
                  variant={success || submitting ? 'disabled' : 'primary'}
                  disabled={success || submitting}
                  sx={styles.button}
                >
                  {submitting ? <Spinner size='20' /> : buttonTitle}
                </Button>
              </Box>
            </Flex>
          </>
        )}
      </form>
    </>
  )

}

export default NewsletterForm

NewsletterForm.propTypes = {
  handleSubmit: PropTypes.func,
  canSubmit: PropTypes.bool,
  submitting: PropTypes.bool,
  message: PropTypes.string,
  success: PropTypes.bool
}
